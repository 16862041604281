import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";



import { v4 as uuidv4 } from 'uuid';

function DnDComponent() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //const [items, setItems] = useState(breakfast.menuItems);
	const [items, setItems] = useState([]);

    const [droppedItems, setDroppedItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [dragDestination, setDragDestination] = useState(null);
	const PAYPAL_CLIENT_ID = 'AabTB9FSNvsz8CA3FuW-T72-h4hal_o_mhf2LODcL0M6k7UsGAcmDv-qSbl9IWfZtNDICKaMPq51zzA8'//"AabTB9FSNvsz8CA3FuW-T72-h4hal_o_mhf2LODcL0M6k7UsGAcmDv-qSbl9IWfZtNDICKaMPq51zzA8";
 //const [activeCategory, setActiveCategory] = useState('Breakfast'); // Default to 'Breakfast'
       const [showPopup, setShowPopup] = useState(false);
const [name, setName] = useState('');
const [phoneNumber, setPhoneNumber] = useState('');
const [address, setAddress] = useState('');
const [specialInstructions, setSpecialInstructions] = useState('');

 const [deliveryOption, setDeliveryOption] = useState('noDelivery'); // New state for delivery option
    const deliveryCharge = 10; // Charge for delivery


    // Check if all required fields are filled out
	
 //const [address, setAddress] = useState('');
 const [tipPercentage, setTipPercentage] = useState(null);
    

	 //const isFormComplete = name && phoneNumber && address && tipPercentage !== null;
  //  const isFormComplete = name && phoneNumber;
   const taxRate = 0.075; // Example: 7.5%

 // const [forceUpdate, setForceUpdate] = useState(false);
   //  const [tipPercentage, setTipPercentage] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(0); // Assuming you have a way to set this


    const [menuData, setMenuData] = useState({});

    // State for dynamically storing categories
    const [categories, setCategories] = useState([]);

const [activeCategory, setActiveCategory] = useState(''); // Initialize without a default
//const [menuData, setMenuData] = useState({});
//const [items, setItems] = useState([]);
//const [categories, setCategories] = useState([]);
 
 const calculateTax = () => totalPrice * taxRate;
const [refreshPayPal, setRefreshPayPal] = useState(0);



useEffect(() => {
    const loadMenuData = async () => {
        try {
            const response = await fetch('/jsons/menu/all.json');
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setMenuData(data);

            const extractedCategories = Object.keys(data).filter(key => key !== 'Object');
            setCategories(extractedCategories);

            // Set the active category to the first category in the list
            if (extractedCategories.length > 0) {
                const firstCategory = extractedCategories[0];
                setActiveCategory(firstCategory);

                // Automatically load items for the first category
                setItems(data[firstCategory] || []);
            }
        } catch (error) {
            console.error('Failed to load menu data:', error);
        }
    };

    loadMenuData();
}, []);

const calculateTotal = () => {
    const tipAmount = totalPrice * (tipPercentage ? tipPercentage / 100 : 0);
    const taxAmount = calculateTax();
    const deliveryAmount = deliveryOption === 'delivery' ? deliveryCharge : 0;
    return totalPrice + tipAmount + taxAmount + deliveryAmount;
};

const calculateTotalWithTipTaxAndDelivery = () => {
        const tipAmount = totalPrice * (tipPercentage ? tipPercentage / 100 : 0);
        const taxAmount = calculateTax();
        const deliveryAmount = deliveryOption === 'delivery' ? deliveryCharge : 0;
        return totalPrice + tipAmount + taxAmount + deliveryAmount;
    };


    // Function to display the tip and tax information
    const displayTipTaxAndDeliveryInfo = () => {
        const tipInfo = tipPercentage ? `Tip: ${tipPercentage}%` : "Tip: Not selected";
        const taxInfo = `Tax: $${calculateTax().toFixed(2)}`;
        const deliveryInfo = deliveryOption === 'delivery' ? `Delivery Charge: $${deliveryCharge}` : "No Delivery Charge";
        return `${tipInfo}, ${taxInfo}, ${deliveryInfo}`;
    };
	
	

const togglePopup = () => {
    setShowPopup(!showPopup);
    setSpecialInstructions("");
    // Toggle force update to re-render PayPal component
    setForceUpdate(prev => !prev);
};
 
let cartSummaryString = ""; // Global variable to store the cart summary string

const generateCartSummary = () => {
    // Reset the global variable at the beginning of each call
    cartSummaryString = "";

    const cartItems = droppedItems.map((item, index) => {
        // Ensure item.price is treated as a number and format it
        const formattedPrice = Number(item.price).toFixed(2);
        const itemString = `${item.title} - $${formattedPrice} |`;

        // Update the global variable with each item's string
        cartSummaryString += itemString + "; ";

        return (
            <div key={index} style={styles.cartItem}>
                <span>{item.title} - </span>
				
				
                <span>${formattedPrice}</span> 
            </div>
        );
    });

    // Optional: Trim the last semicolon and space from the global string
    cartSummaryString = cartSummaryString.trim().slice(0, -1);
    console.log(cartSummaryString);
    return cartItems;
};


	  const initialOptions = {
        "client-id": PAYPAL_CLIENT_ID,
        currency: "USD",
		//"disable-funding": "paylater",
        // Other PayPal configurations
    };
	
	

const createOrder = (data, actions) => {
    const total = calculateTotalWithTipTaxAndDelivery(); // Ensure this calculates the total dynamically
    
    return actions.order.create({
        purchase_units: [{
            amount: {
                value: total.toFixed(2), // Make sure this is a string
            },
        }],
    });
};

// Example key generation based on state
const paypalKey = `paypal-button-${totalPrice}-${tipPercentage}-${deliveryOption}-${refreshPayPal}`;

// Include this key on the PayPalButtons component



    const onApprove = (data, actions) => {
        return actions.order.capture().then((details) => {
            console.log("Order Approved", details);
            // Additional logic after successful payment
        });
    };

	
	 const isFormComplete = name && phoneNumber && address && tipPercentage !== null && deliveryOption;
	
	const [message, setMessage] = useState("");
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        // Clean up
        return () => window.removeEventListener('resize', handleResize);
    }, []);

const handleChangeCategory = (category) => {
    setActiveCategory(category);
    // Assuming menuData is structured with category names as keys
    const categoryItems = menuData[category];
    if (categoryItems) {
        setItems(categoryItems);
    } else {
        // Handle case where the category does not exist or has no items
        console.error(`No items found for category: ${category}`);
        setItems([]);
    }
};

const updateTotalPrice = (items) => {
    const newTotalPrice = items.reduce((total, item) => total + Number(item.price), 0);
    setTotalPrice(newTotalPrice);
};

const updateTotalAndRefreshPayPal = (newTipPercentage, newDeliveryOption) => {
    setTipPercentage(newTipPercentage); // Or however you update your states
    setDeliveryOption(newDeliveryOption); // Example for delivery option
    // Any other state updates affecting the price should go here
    setRefreshPayPal(prev => prev + 1); // Increment to force PayPalButtons to re-render
};


    const handleDragEnd = (result) => {
        const { source, destination } = result;
        setDragDestination(destination);

        if (!destination) {
            if (source.droppableId === 'droppable-2') {
                const newDroppedItems = [...droppedItems];
                newDroppedItems.splice(source.index, 1);
                setDroppedItems(newDroppedItems);
                updateTotalPrice(newDroppedItems);
            }
            return;
        }

        if (source.droppableId === 'droppable-1' && destination.droppableId === 'droppable-2') {
            setDroppedItems(prev => {
                const newItems = [...prev, { ...items[source.index], id: uuidv4() }];
                updateTotalPrice(newItems);
				console.log(totalPrice.toFixed(2))
                return newItems;
            });
        }

        // Further logic for other cases involving destination can go here.
        // Just make sure to check the existence of destination before using it.
    };

  useEffect(() => {
    console.log("Total Price Updated: ", totalPrice.toFixed(2));
}, [totalPrice]);


    const calculateTotalWithTip = () => {
        if (tipPercentage) {
            return totalPrice + (totalPrice * tipPercentage / 100);
        }
        return totalPrice;
    };
	
	


const activeButtonStyle = {
    backgroundColor: '#4CAF50', // Example green background for active button
    color: 'white',
};

const inactiveButtonStyle = {
    backgroundColor: '#e7e7e7', // Example grey background for inactive button
    color: 'black',
};


const isMobile = windowWidth < 700;
 const styles = {
	buttonCheckout: {
        backgroundColor: 'black',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
    },
	    scrollPane: {
			
        maxHeight: '300px', // Adjust the height as needed
        overflowY: 'auto',
        padding: '10px',
        border: '1px solid #ccc',
        margin: '10px 0',
        backgroundColor: '#f0f0f0'
    },
    popupBackground: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    popupContent: {
		      position: 'relative', // This ensures the child elements can be positioned relative to this div
        padding: '20px',
       // backgroundColor: 'white',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '5px',
        position: 'relative',
        maxWidth: '600px',
        width: '80%',
    },
    closeButton: {
		   position: 'absolute', // Position the button absolutely within the popupContent
      
        right: '10px',
        position: 'absolute',
        top: '30px',
        right: '10px',
        border: 'none',
        background: 'none',
        fontSize: '20px',
        cursor: 'pointer',
    }, 
        container: {
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-around',
            alignItems: 'flex-start',
            padding: '20px',
            height: isMobile ? 'calc(100vh - 60px)' : 'auto', // Adjust for the height of the button container
        },
        droppableContainer: {
            backgroundColor: '#f0f0f0',
            overflowY: 'auto',
            border: '1px solid #ccc',
            borderRadius: '4px',
            margin: isMobile ? '0 0 20px 0' : '0',
            width: isMobile ? '100%' : '48%',
            height: isMobile ? '33vh' : '400px', // 1/3 of the viewport height on mobile
        },
        draggableContainer: {
            backgroundColor: '#f0f0f0',
            overflowY: 'auto',
            border: '1px solid #ccc',
            borderRadius: '4px',
            width: isMobile ? '100%' : '48%',
            height: isMobile ? 'calc(66vh - 60px)' : '400px', // 2/3 of the viewport height on mobile, adjusted for button container height
            position: isMobile ? 'fixed' : 'relative',
            bottom: isMobile ? '0' : 'auto',
            left: isMobile ? '0' : 'auto',
        },
      
        draggableItem: {
            padding: '10px',
            borderBottom: '1px solid #ccc',
            display: 'flex',
            alignItems: 'center',
        },
        image: {
            width: '50px',
            height: '50px',
            marginRight: '10px',
        },
		        buttonContainer: {
            display: 'flex',
            overflowX: 'auto', // Horizontal scroll
            padding: '10px 0',
            whiteSpace: 'nowrap', // Prevent buttons from wrapping
        },
        button: {
            backgroundColor: 'black',
            color: 'white',
            border: '1px solid white',
            borderRadius: '4px',
            padding: '10px 20px',
            margin: '0 10px',
            cursor: 'pointer',
            whiteSpace: 'nowrap', // Prevent text in buttons from wrapping
        },
        emptyMessage: {
            padding: '100px',
            textAlign: 'center',
            color: '#aaa',
            fontSize: '1em'
        },
		    textarea: {
        width: '100%',
        height: '100px',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '4px',
        border: '1px solid #ccc',
        resize: 'none', // Disables resizing of textarea
    },
	    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
		
    };



    return (
        <DragDropContext onDragEnd={handleDragEnd}>
              <div style={styles.buttonContainer}>
            {categories.map(category => (
                <button 
                    key={category}
                    style={activeCategory === category ? styles.activeButton : styles.button}
                    onClick={() => handleChangeCategory(category)}
                >
                    {category}
                </button>
            ))}
        </div>

            <div style={styles.container} className="responsive-container">
		
			
                <Droppable droppableId="droppable-1" isDropDisabled={true}>
                    {(provided) => (
                        <div 
                            ref={provided.innerRef} 
                            {...provided.droppableProps} 
                            style={{
                                ...styles.droppableContainer, 
                                width: windowWidth < 700 ? '95%' : '75%', // Adjust width based on windowWidth
                            }}
                        >
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps.style,
                                                ...styles.draggableItem,
                                                backgroundColor: snapshot.isDragging ? "#eaeaea" : "white",
                                            }}
                                        >
                                            <img 
                                                src={item.image} 
                                                alt={item.title} 
                                                style={{
                                                    ...styles.image,
                                                    width: snapshot.isDragging ? '100px' : '50px', // Increase size when dragging
                                                    height: snapshot.isDragging ? '100px' : '50px',
                                                }} 
                                            />
                                            <div>
                                                <div>{item.title}</div>
                                                <div>{item.description}</div>
                                                <div>{item.price} </div> {/* Display price */}
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

                <Droppable droppableId="droppable-2">
                    {(provided) => (
                        <div 
                            ref={provided.innerRef} 
                            {...provided.droppableProps} 
                            style={{
                                ...styles.droppableContainer, 
                                width: windowWidth < 700 ? '95%' : '25%', // Adjust width based on windowWidth
                            }}
                        >
                            {droppedItems.length === 0 ? (
                                <div style={styles.emptyMessage}>Drag items here</div>
                            ) : (
                                droppedItems.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                    ...styles.draggableItem,
                                                    backgroundColor: snapshot.isDragging ? "#eaeaea" : "white",
                                                }}
                                            >
                                                <img src={item.image} alt={item.title} style={styles.image} />
                                                <div>
                                                    <div>{item.title}</div>
                                                    <div>{item.price} </div> {/* Display price without description */}
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

                  
        <div style={{ padding: '20px' }}>
                Total Price: {totalPrice.toFixed(2)} 
                <button 
                    style={styles.buttonCheckout}
                    onClick={togglePopup}
                >
                    Checkout
                </button>
            </div>

            {showPopup && (
          <div style={styles.popupBackground}>
        <div style={styles.popupContent}>
            <button onClick={togglePopup} style={styles.closeButton}>X</button>
            <h2>Cart Summary</h2>
            <div style={styles.scrollPane}>
              
                <input
                    style={styles.input}
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    style={styles.input}
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <input
                    style={styles.input}
                    placeholder="Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
                <textarea
                    style={styles.textarea}
                    placeholder="Special instructions for your order..."
                    value={specialInstructions}
                    onChange={(e) => setSpecialInstructions(e.target.value)}
                ></textarea>

                {/* Tip Buttons */}
				
				  {generateCartSummary()}
                {/* Display selected tip percentage, calculated tax, and delivery charge */}
                <p>{displayTipTaxAndDeliveryInfo()}</p>
                <p>Total Price with Tip, Tax, and Delivery: ${calculateTotalWithTipTaxAndDelivery().toFixed(2)}</p>

           <div>
    <button
        onClick={() => {
            setTipPercentage(10);
            setRefreshPayPal(prev => prev + 1); // Force PayPal buttons to refresh
        }}
        style={tipPercentage === 10 ? activeButtonStyle : inactiveButtonStyle}
    >
        Add 10% Tip
    </button>
    <button
        onClick={() => {
            setTipPercentage(15);
            setRefreshPayPal(prev => prev + 1); // Force PayPal buttons to refresh
        }}
        style={tipPercentage === 15 ? activeButtonStyle : inactiveButtonStyle}
    >
        Add 15% Tip
    </button>
    <button
        onClick={() => {
            setTipPercentage(20);
            setRefreshPayPal(prev => prev + 1); // Force PayPal buttons to refresh
        }}
        style={tipPercentage === 20 ? activeButtonStyle : inactiveButtonStyle}
    >
        Add 20% Tip
    </button>
</div>

                {/* Delivery Option Buttons */}
          <div>
    <button
        onClick={() => {
            setDeliveryOption('noDelivery');
            setRefreshPayPal(prev => prev + 1); // Force PayPal buttons to refresh
        }}
        style={deliveryOption === 'noDelivery' ? activeButtonStyle : inactiveButtonStyle}
    >
        No Delivery
    </button>
    <button
        onClick={() => {
            setDeliveryOption('delivery');
            setRefreshPayPal(prev => prev + 1); // Force PayPal buttons to refresh
        }}
        style={deliveryOption === 'delivery' ? activeButtonStyle : inactiveButtonStyle}
    >
        Delivery (+$10)
    </button>
</div>

				
				

                {/* PayPal Buttons only shown if form is complete */}
                {isFormComplete && (
<PayPalScriptProvider options={initialOptions}>
    <PayPalButtons
        key={`paypal-buttons-${refreshPayPal}`} // Forces re-render on change
        createOrder={(data, actions) => {
            return actions.order.create({
                purchase_units: [{
                    amount: {
                        value: calculateTotal().toFixed(2), // Use the dynamically calculated total
                    },
                }],
            });
        }}
        onApprove={onApprove}
        // Any other props you need
    />
</PayPalScriptProvider>

                )}
            </div>
        </div>
    </div>
        )}
       
            </div>
			
        </DragDropContext>
    );
}

export default DnDComponent;
