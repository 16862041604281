//import React from 'react';
import React, { useEffect, useState } from 'react';
//import ImageSlider from './ImageSlider';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



const styles = {
  header: {
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px 20px',
    position: 'relative',
    height: '60px',
    width: '100%', // Ensure the header is full width
    margin: 0, // Remove any default margin
	
	
  },
  logo: {
    position: 'absolute', // Absolute positioning
    top: '50%', // Center vertically in the header
    left: '10px', // Adjust the distance from the left
    transform: 'translateY(-50%)', // Adjust vertical centering
    maxWidth: '150px', // Control the size of the logo
    height: 'auto',
	zIndex: 1000,
  },

  contactInfo: {
    color: 'white', // Color for text
    textAlign: 'right', // Align text to the right
    marginRight: '20px', // Right margin to create space from the edge
    // If you need vertical spacing as well, you can use padding or margin properties
    // Add additional styling for the text as needed
  },
  logoContainer: {
    position: 'absolute', // Positioning the logo container absolutely
    top: '70%', // Centering it vertically
    left: '50%', // Centering it horizontally
    transform: 'translate(-50%, -50%)', // Adjusting the exact center position
    maxWidth: '150px', // Adjust logo width as needed
    height: 'auto', // Logo height will adjust automatically
  },


  description: {
    color: '#333', // Default text color, change as needed
    padding: '0px', // Adjust padding as needed
    fontSize: '16px', // Default font size, change as needed
    lineHeight: '1.6', // Adjust line height as needed
    textAlign: 'justify', // For better readability
    // Add other CSS properties here if necessary
  },
   contactText: {
    whiteSpace: 'pre-line', // Ensures natural line breaks at spaces and newlines
    // Add more styles as needed for text formatting
  },
  operatingHours: {
    fontWeight: 'bold', // This makes the text bold
  },
      container: {
      width: '80%',
      margin: '0 auto',
    },
    slide: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    image: {
      width: '100%',
      marginBottom: '10px',
    },
    title: {
      fontSize: '24px',
      marginBottom: '5px',
    },
    description: {
      fontSize: '16px',
    },
};


// ... import styles if needed

function Home() {
    const [slides, setSlides] = useState([]);
    const [generalDescription, setGeneralDescription] = useState('');
    const [operatingHours, setOperatingHours] = useState([]);
const [addressDescription, setAddressDescription] = useState('');
const [phoneDescription, setPhoneDescription] = useState('');
 const [logoDescription, setLogoDescription] = useState('');
   
   
useEffect(() => {
    const loadHomeData = async () => {
        try {
            const response = await fetch('/jsons/menu/home.json');
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();

            // Set slides
            setSlides(data.slides || []);

            // Extract general description, operating hours, address, phone, and logo
            const homeInfo = data.home || [];
            const mainInfo = homeInfo.find(item => item.title === 'main');
            const hoursInfo = homeInfo.find(item => item.title === 'hours');
            const myAddress = homeInfo.find(item => item.title === 'address');
            const phone = homeInfo.find(item => item.title === 'phone');
            const logo = homeInfo.find(item => item.title === 'logo');

            if (mainInfo) setGeneralDescription(mainInfo.description);
            if (hoursInfo) setOperatingHours(hoursInfo.description.split(','));
            if (myAddress) setAddressDescription(myAddress.description);
            if (phone) setPhoneDescription(phone.description);
            if (logo) setLogoDescription(logo.image); // Use logo.image instead of logo.description
        } catch (error) {
            console.error('Failed to load home data:', error);
        }
    };

    loadHomeData();
}, []);



    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
    };

    const address = addressDescription;
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    //const phoneNumber = "(707) 994-6450";
	
	
	const phoneNumber = phoneDescription;
    const phoneUrl = `tel:${phoneNumber}`;
	var myLogo = logoDescription;

    if (!slides.length && !generalDescription) {
        return <div>Loading...</div>;
    }


    return (
        <div>
            <div style={styles.header}>
                <img src={logoDescription} alt="Main Street Bar & Grill Logo" style={styles.logo} />
                <div style={styles.contactInfo}>
                    <div>Main Street Bar & Grill</div>
                    <div style={styles.contactText}>
                        <a href={mapsUrl} target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
                            {address}
                        </a>
                    </div>
                    <div style={styles.contactText}>
                        <a href={phoneUrl} style={{ color: 'white', textDecoration: 'none' }}>{phoneNumber}</a>
                    </div>
                </div>
            </div>
            <div style={styles.container}>
                <Slider {...settings}>
                    {slides.map((slide, index) => (
                        <div key={index} style={styles.slide}>
                            <img src={slide.image} alt={slide.title} style={styles.image} />
                            <div style={styles.title}>{slide.title}</div>
                            <div style={styles.description}>{slide.description}</div>
                        </div>
                    ))}
                </Slider>
            </div>

            {/* Description and operating hours section with styles */}
            <div style={styles.description}>
                <p style={styles.operatingHours}>Here are our operating hours:</p>
                <ul style={styles.operatingHours}>
                    {operatingHours.map((day, index) => (
                        <li key={index}>{day}</li>
                    ))}
                </ul>
                {generalDescription}
            </div>
        </div>
    );
}

export default Home;
