import React, { useState, useEffect } from 'react';

function Schedule() {
    const [scheduleData, setScheduleData] = useState([]);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 480);

    useEffect(() => {
        const fetchEventData = async () => {
            try {
                const response = await fetch('/jsons/menu/events.json');
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setScheduleData(data.content || []);
            } catch (error) {
                console.error('Error fetching event data:', error);
            }
        };

        fetchEventData();

        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 480);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const styles = {
        container: {
            padding: '10px',
        },
        scheduleItem: (bgColor) => ({
            border: '1px solid #ccc',
            padding: '20px',
            margin: '20px 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: bgColor,
        }),
        image: {
            maxWidth: isLargeScreen ? '600px' : '300px',
            marginBottom: '20px',
        },
        title: {
            marginBottom: '10px',
        },
        text: {
            marginBottom: '5px',
        },
    };

    return (
        <div style={styles.container}>
            {scheduleData.map((item, index) => (
                <div key={index} style={styles.scheduleItem(item.bgColor)}>
                    <img src={item.image} alt={item.title} style={styles.image} />
                    <h2 style={styles.title}>{item.title}</h2>
                    <p style={styles.text}>{item.text}</p>
                    <p style={styles.text}>{item.date}</p>
                </div>
            ))}
        </div>
    );
}

export default Schedule;
