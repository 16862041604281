import React, { useState, useEffect } from 'react';
//import CustomSelectable from './CustomSelectable';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';








function EventForm() {
	
	const PAYPAL_CLIENT_ID = 'AabTB9FSNvsz8CA3FuW-T72-h4hal_o_mhf2LODcL0M6k7UsGAcmDv-qSbl9IWfZtNDICKaMPq51zzA8'//"AabTB9FSNvsz8CA3FuW-T72-h4hal_o_mhf2LODcL0M6k7UsGAcmDv-qSbl9IWfZtNDICKaMPq51zzA8";
 
 
 
 const [categories, setCategories] = useState([]);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [eventDate, setEventDate] = useState('');
  const [time, setTime] = useState('');
  const [numberOfPeople, setNumberOfPeople] = useState(10);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [specialInstructions, setSpecialInstructions] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [formSummary, setFormSummary] = useState('');

 const [selectedItems, setSelectedItems] = useState({});
  const selectedCategory = categories[selectedCategoryIndex];
    const [termsOfServiceText, setTermsOfServiceText] = useState('');

  
    //const termsOfServiceText = "Your Terms of Service Here"; // Replace with your actual terms

 useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('/jsons/menu/reservations.json');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setCategories(data.categories);

        // Set terms of service text
        if (data.terms && data.terms.length > 0) {
          setTermsOfServiceText(data.terms[0].description);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCategories();
  }, []);
  
  
  const handleCategoryClick = (index) => {
    setSelectedCategoryIndex(index);
  };
  
  
  const initialOptions = {
  "client-id": PAYPAL_CLIENT_ID,
  currency: "USD",
  // Other PayPal configurations
};

const createOrder = (data, actions) => {
  const totalPrice = calculateTotalPrice();
  console.log("Creating order. Total Price: ", totalPrice.toFixed(2));
  
    

  return actions.order.create({
    purchase_units: [{
      amount: {
        value: totalPrice.toFixed(2),
      },
    }],
  });
};

const onApprove = (data, actions) => {
  return actions.order.capture().then((details) => {
    console.log("Order Approved", details);
    // Additional logic after successful payment
  });
};


const toggleItemSelection = (itemName) => {
    setSelectedItems(prevSelectedItems => ({
      ...prevSelectedItems,
      [itemName]: !prevSelectedItems[itemName] // Toggle selection
    }));
  };


  const handleSubmit = (event) => {
    event.preventDefault();
  const selectedCategory = categories[selectedCategoryIndex];
  const selectedItemsDetails = selectedCategory.insideArray.filter(item => selectedItems[item.name]);


  let message = '';
  if (!name) {
    message += 'Name is required.\n';
  }
  if (!phoneNumber) {
    message += 'Phone Number is required.\n';
  }
  if (!eventDate) {
    message += 'Event Date is required.\n';
  }
   if (!time) {
    message += 'Event Time is required.\n';
  }
     if (!agreeToTerms) {
    message += 'aggree to  terms of service is required.\n';
  }
  
  if (Object.keys(selectedItems).length === 0) {
    message += 'At least one item must be selected.\n';
  }
  

  if (message) {
    setValidationMessage(message);
    return;
  }



    // Construct the form summary
    let summary = `Event Details:\n`;
    summary += `Name: ${name}\n`;
    summary += `Phone Number: ${phoneNumber}\n`;
	 summary += `Event Type: ${selectedCategory.category}\n`; // Add event type
summary += `Selected Items: ${selectedItemsDetails.map(item => `${item.name} - ${item.price}`).join(', ')}\n`;
 
  summary += `Event Date: ${eventDate}||\n`;
  summary += `Event Time: ${time}||\n`;
  summary += `Number of People: ${numberOfPeople}||\n`;

  summary += `Special Requests: ${specialInstructions}||\n`;
  summary += `Total Price: $${calculateTotalPrice()}`;

    // ... (add other form details to the summary)

    setFormSummary(summary);
    setIsOverlayVisible(true); // Show the overlay
	
	
	
	
	
	
	  const postData = {
  date: new Date().toISOString(),
  orderDetails: summary,
};

const formBody = Object.keys(postData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(postData[key])).join('&');

fetch('https://blocksnips.nfshost.com/myphp/testdb.php', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  body: formBody,
})
.then(response => {
  if (!response.ok) {
    throw new Error('Network response was not ok ' + response.statusText);
  }
  return response.json();
})
.then(data => {
  console.log('Success:', data);
})
.catch((error) => {
  console.error('Error:', error);
});
  };


const calculateTotalPrice = () => {
  // Ensure categories are loaded and selectedCategoryIndex is valid
  if (categories.length > 0 && categories[selectedCategoryIndex]) {
    const selectedCategory = categories[selectedCategoryIndex];
    const selectedItemsDetails = selectedCategory.insideArray.filter(item => selectedItems[item.name]);

    // Calculate the total cost of selected items
    const selecteditemscost = selectedItemsDetails.reduce((total, item) => {
      const itemPrice = parseFloat(item.price); // Convert string to number
      return total + (itemPrice * (selectedItems[item.name] ? 1 : 0));
    }, 0);

    // Calculate total price based on number of people and selected items cost
    var num = numberOfPeople * 10 + selecteditemscost;
    return num;
  }
  return 0; // Return 0 if data is not loaded or index is invalid
};



  const toggleTermsModal = () => {
    setShowTermsModal(!showTermsModal);
  };

  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };
  
  


 

  const styles = {
    scrollableContainer: {
      display: 'flex',
      overflowX: 'auto',
      padding: '10px 0',
    },
    selectableItem: {
      minWidth: '200px',
      marginRight: '10px',
    },
	    fullScreenWidth: {
      width: '100%', // Full screen width
      boxSizing: 'border-box', // Ensures padding and borders are included in the total width
   height: '100px',
   },
     overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure it's above everything else
  },
  overlayContent: {
    width: '75%',
    height: '75%',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  },
    modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    width: '75%',
    height: '75%',
    backgroundColor: 'white',
    overflowY: 'auto', // Allow scrolling for lengthy content
    padding: '20px',
    borderRadius: '10px',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  },
      categoriesContainer: {
      display: 'flex',
      overflowX: 'auto',
      padding: '10px',
      backgroundColor: '#f0f0f0'
    },
    category: {
			
    
      marginRight: '20px',
      padding: '10px',
      cursor: 'pointer',
      border: '2px solid transparent',
      textAlign: 'center'
    },
    selectedCategory: {
	
      borderColor: 'blue'
    },
    itemsContainer: {
      marginTop: '20px'
    },
    itemsList: {
      display: 'flex',
      overflowX: 'auto',
      padding: '10px',
      backgroundColor: '#f0f0f0'
    },
	   image: {
      maxWidth: '200px',
      maxHeight: '200px',
      objectFit: 'contain' // This ensures the image scales down to fit the constraints while maintaining its aspect ratio
    },
    item: {
      marginRight: '20px',
      padding: '10px',
      textAlign: 'center'
    }
  };






 return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          Event Date:
          <input type="date" value={eventDate} onChange={e => setEventDate(e.target.value)} />
        </label>
      </div>
      
      <div>
        <label>
          Time:
          <input type="time" value={time} onChange={e => setTime(e.target.value)} />
        </label>
      </div>

      <div>
        <label>
          Number of People:
          <input 
            type="number"
            min="10"
            step="5"
            value={numberOfPeople}
            onChange={e => setNumberOfPeople(Number(e.target.value))}
          />
        </label>
      </div>

      <div>
        <label>
          Name:
          <input type="text" value={name} onChange={e => setName(e.target.value)} />
        </label>
      </div>

      <div>
        <label>
          Phone Number:
          <input type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
        </label>
      </div>

 <div style={styles.categoriesContainer}>
        {categories.map((category, index) => (
          <div
            key={index}
            style={{ ...styles.category, ...(index === selectedCategoryIndex ? styles.selectedCategory : {}) }}
            onClick={() => handleCategoryClick(index)}
          >
            <img src={category.imageUrl} alt={category.category} style={styles.image} />
            <p>{category.category}</p>
          </div>
        ))}
      </div>

      <div style={styles.itemsContainer}>
        <h2>Items in: {categories[selectedCategoryIndex]?.category}</h2>
        <div style={styles.itemsList}>
          {(categories[selectedCategoryIndex]?.insideArray || []).map((item, itemIndex) => (
            <div 
              key={itemIndex} 
              style={{ ...styles.item, backgroundColor: selectedItems[item.name] ? '#c8e6c9' : '' }}
              onClick={() => toggleItemSelection(item.name)}
            >
              <img src={item.imageUrl} alt={item.name} style={styles.image} />
              <p>{item.name} - {item.price}</p>
            </div>
          ))}
        </div>
      </div>


      <div>
        <label>
          Special Requests:
          <textarea
            value={specialInstructions}
            onChange={e => setSpecialInstructions(e.target.value)}
            style={styles.fullScreenWidth}
          />
        </label>
      </div>

      <div>
        Total Price: ${calculateTotalPrice()}
      </div>

      <div>
        <label>
          <input 
            type="checkbox"
            checked={agreeToTerms}
            onChange={e => setAgreeToTerms(e.target.checked)}
          />
          I agree to the Terms of Service
        </label>
        <button type="button" onClick={toggleTermsModal}>View Terms</button>
      </div>

      {showTermsModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <span style={styles.closeButton} onClick={toggleTermsModal}>X</span>
            <pre>{termsOfServiceText}</pre>
          </div>
        </div>
      )}

      {validationMessage && (
        <div style={{ color: 'red' }}>
          <pre>{validationMessage}</pre>
        </div>
      )}

      <button type="submit">Submit</button>

      {isOverlayVisible && (
        <div style={styles.overlay}>
          <div style={styles.overlayContent}>
            <span style={styles.closeButton} onClick={toggleOverlay}>X</span>
            <pre>{formSummary}</pre>
					     <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
        />
      </PayPalScriptProvider>
          </div>

        </div>
      )}
    </form>
  );
}


export default EventForm;
