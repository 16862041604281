import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import Events from './Events';
import Menu from './Menu';
import Bookings from './reservations';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);
  const globalStyles = {
    body: {
      margin: 0,
      padding: 0,
      // other global styles if needed
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    body: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Arial, sans-serif',
      minHeight: '100vh',
      overflow: 'hidden',
      margin: 0,
      padding: 0,
    },
    content: {
      flexGrow: 1,
      overflowY: 'auto',
      backgroundColor: '#f0f0f0',
      padding: '20px',
      paddingBottom: isMobile ? '60px' : '50px', // Footer height + padding
    },
    footer: {
      backgroundColor: 'black',
      padding: '10px 0',
      textAlign: 'center',
      color: 'white',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
    },
    navList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
    },
    navItem: {
      margin: '2px',
    },
    navLink: {
      textDecoration: 'none',
      color: 'white',
      fontSize: isMobile ? '16px' : '15px',
      padding: isMobile ? '5px 5px' : '8px 3px',
      border: '1px solid white',
      borderRadius: '5px',
    },
    logo: {
      height: '40px', // Adjust the size as needed
      position: 'absolute',
      bottom: '0px', // Adjust distance from bottom
      right: '10px', // Adjust distance from right
    },
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  
  return (
   <Router>
      <div style={styles.body}>
        <div style={styles.content}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/events" element={<Events />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/bookings" element={<Bookings />} />
          </Routes>
        </div>

        <footer style={styles.footer}>
          <nav>
            <ul style={styles.navList}>
              <li style={styles.navItem}><Link to="/" style={styles.navLink} onClick={scrollToTop}>Home</Link></li>
              <li style={styles.navItem}><Link to="/events" style={styles.navLink} onClick={scrollToTop}>Events</Link></li>
              <li style={styles.navItem}><Link to="/menu" style={styles.navLink} onClick={scrollToTop}>Menu</Link></li>
              <li style={styles.navItem}><Link to="/bookings" style={styles.navLink} onClick={scrollToTop}>Bookings</Link></li>
            </ul>
          </nav>
          <a href="https://example.com" target="_blank" rel="noopener noreferrer">
            <img src={`https://blocksnips.nfshost.com/pics/logos/bestLogoMe.png?v=${new Date().getTime()}`} alt="Web Creator Logo" style={styles.logo} />
          </a>
        </footer>
      </div>
    </Router>
  );
}

export default App;
